import { GridRenderCellParams, useGetLocale } from '@timelog/ui-library';
import { useTranslation } from 'react-i18next';
import { translationAnyText } from 'src/utils/translation';
import { useGetPlannedAbsenceReportingWidget } from 'src/apis/reportingWidgetsAPI';
import { convertDateToShortDateLocaleString } from 'src/utils/date/date';
import { IPlannedAbsenceWidgetData, IPlannedAbsenceInfo } from 'src/apis/types/plannedAbsenceAPI';
import { useState } from 'react';
import ReportingWidgetWithTable from '../ReportingWidgetWithTable';

const getClassNameByStatus = (status: string): string => {
  switch (status) {
    case 'absencecompensated':
    case 'absencenoncompensated':
    case 'illness':
    case 'vacation':
      return 'bgInfo';
    case 'publicholiday':
    case 'weekend':
      return 'bgGray';
    default:
      return '';
  }
};

const parseAbsenceData = (data: IPlannedAbsenceInfo[]) => {
  const absenceData = data.map((item) => ({
    [item.date]: item.status.toLowerCase(),
  }));
  return absenceData;
};

const parseRowData = (data: IPlannedAbsenceWidgetData[]) => {
  const newRow = data.map((item) => {
    const userData = {
      name: item.fullName,
      initial: item.initials,
    };
    const absenceData = parseAbsenceData(item.absenceInfo);
    const userAbsenceData = Object.assign(userData, ...absenceData);
    return userAbsenceData;
  });

  return newRow;
};

export const PlannedAbsenceReportingWidget = () => {
  const locale = useGetLocale();
  const { t } = useTranslation('reportingWidgets');
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 15,
  });
  const { widgetName, widgetData, paginationInfo, isError, isLoading } =
    useGetPlannedAbsenceReportingWidget(paginationModel);
  const isEmptyState = widgetData.length <= 0;

  const getColumnData = ({ absenceInfo }: IPlannedAbsenceWidgetData) => {
    const absenceColumnData = absenceInfo.map(({ date }: IPlannedAbsenceInfo) => ({
      headerName: convertDateToShortDateLocaleString(new Date(date), locale),
      key: date,
      headerAlign: 'center',
      width: 75,
      cellClassName: (params: GridRenderCellParams) => getClassNameByStatus(params.row[date]),
      renderCell: () => <span />,
    }));

    const columnData = [
      {
        headerName: t('TableHeaderInitials'),
        key: 'initial',
      },
      {
        headerName: t('TableHeaderName'),
        key: 'name',
        width: 200,
      },
      ...absenceColumnData,
    ];
    return columnData;
  };

  const rowData = isEmptyState ? [] : parseRowData(widgetData);
  const colData = isEmptyState ? [] : getColumnData(widgetData[0]);

  return (
    <ReportingWidgetWithTable
      name={translationAnyText(t, widgetName)}
      data={rowData}
      columnData={colData}
      hasPagination
      paginationModel={paginationModel}
      setPaginationModel={setPaginationModel}
      rowCount={Number(paginationInfo.totalRecord)}
      isEmpty={isEmptyState}
      isError={isError}
      isLoading={isLoading}
      disableCellBorder={false}
    />
  );
};

export default PlannedAbsenceReportingWidget;
