import { useGetLocale } from '@timelog/ui-library';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { translationAnyText } from 'src/utils/translation';
import { useGetAverageHourlyRateReportingWidget } from 'src/apis/reportingWidgetsAPI';
import { IAverageHourlyRateWidgetData } from 'src/apis/types/averageHourlyRateAPI';
import ReportingWidgetWithTable from '../ReportingWidgetWithTable';

const parseRowData = (data: IAverageHourlyRateWidgetData[], locale: string) => {
  const newRow = data.map((item) => ({
    customer: item.customerName,
    project: item.projectName,
    hoursSpent: item.actualHours.toLocaleString(locale),
    averageHourlyRate: item.rate.toLocaleString(locale),
  }));
  return newRow;
};

export const AverageHourlyRateReportingWidget = () => {
  const locale = useGetLocale();
  const { t } = useTranslation('reportingWidgets');
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 15,
  });
  const { widgetName, widgetData, currencyAbb, paginationInfo, isError, isLoading } =
    useGetAverageHourlyRateReportingWidget(paginationModel);

  const getColumnData = () => [
    {
      headerName: t('TableHeaderProject'),
      key: 'project',
      flex: 3,
    },
    {
      headerName: t('TableHeaderCustomer'),
      key: 'customer',
      flex: 3,
    },
    {
      headerName: t('TableHeaderHoursSpent'),
      key: 'hoursSpent',
      align: 'right',
      flex: 3,
    },
    {
      headerName: `${t('TableHeaderAHR')} (${currencyAbb})`,
      key: 'averageHourlyRate',
      align: 'right',
      flex: 3,
    },
  ];

  return (
    <ReportingWidgetWithTable
      name={translationAnyText(t, widgetName)}
      data={parseRowData(widgetData, locale)}
      columnData={getColumnData()}
      hasPagination
      paginationModel={paginationModel}
      setPaginationModel={setPaginationModel}
      rowCount={Number(paginationInfo.totalRecord)}
      isEmpty={widgetData.length <= 0}
      isError={isError}
      isLoading={isLoading}
    />
  );
};

export default AverageHourlyRateReportingWidget;
