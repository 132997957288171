import cx from 'classnames';
import { useAutoId } from 'hooks/useAutoId';
import styles from './ProgressBar.module.scss';

type TProgressBarStatus = 'default' | 'error' | 'success' | 'warning';

export interface IProgressBar {
  value: number;
  label: string;
  id?: string;
  barStatus?: TProgressBarStatus;
  showValue?: boolean;
}

export const ProgressBar = ({
  value,
  label,
  id,
  barStatus = 'default',
  showValue,
}: IProgressBar) => {
  const autoId = useAutoId(id);
  return (
    <div className={cx(styles.wrapper, { [styles.showValue]: showValue })}>
      <progress
        className={cx({ [styles[barStatus]]: barStatus })}
        id={`ProgressBar-${autoId}`}
        data-automation-id="ProgressBar"
        aria-label={label}
        value={value}
        max="100"
      >
        {value} %
      </progress>
    </div>
  );
};

export default ProgressBar;
